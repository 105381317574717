import * as React from "react"

const TTLogo = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1719.156 1054.05"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:#af204a;stroke:#fff;stroke-miterlimit:10;stroke-width:.5px}"
        }
      </style>
    </defs>
    <path
      className="cls-1"
      d="M1086.812 549.965c36.553-43.5 104.177-115.787 178.198-193.305-32.442 3.599-65.34 7.523-94.583 11.122-7.766.98-9.138-.328-7.31-2.944 5.94-9.487 9.138-17.335 14.165-21.916 7.766-6.868 14.622-11.119 35.181-13.41 15.994-1.962 49.347-4.579 84.53-6.868 4.113-4.252 6.854-7.523 9.139-10.14 4.568-4.906 6.853-5.233 15.079-2.289 5.94 2.29 14.164 5.888 22.387 9.812 21.932-.982 42.039-1.635 57.115-1.635 12.337 0 20.105 2.944 29.243 7.196 10.966 5.233 15.079 7.195 15.079 9.484 0 1.31-1.828 2.29-5.483 2.617-16.907 1.637-51.175 5.233-92.299 9.814-30.155 34.67-123.824 133.775-185.05 191.014-10.966 10.141-18.734 11.776-29.7 11.776-22.847.654-33.813 3.598-42.949 3.598-2.743 0-5.485-.654-2.743-3.926Z"
    />
    <path
      className="cls-1"
      d="M1162.755 641.645c36.554-43.502 104.177-115.788 178.2-193.305-32.443 3.598-65.342 7.522-94.584 11.121-7.766.98-9.139-.327-7.311-2.944 5.94-9.487 9.138-17.334 14.166-21.915 7.766-6.868 14.621-11.12 35.181-13.41 15.994-1.963 49.347-4.58 84.53-6.869 4.113-4.251 6.854-7.522 9.139-10.14 4.568-4.905 6.853-5.232 15.079-2.288 5.94 2.289 14.163 5.888 22.387 9.811 21.932-.982 42.038-1.634 57.115-1.634 12.336 0 20.105 2.944 29.243 7.195 10.966 5.233 15.079 7.195 15.079 9.485 0 1.31-1.828 2.29-5.483 2.616-16.907 1.637-51.175 5.234-92.299 9.814-30.155 34.67-123.824 133.775-185.05 191.014-10.966 10.141-18.734 11.776-29.7 11.776-22.847.655-33.813 3.599-42.95 3.599-2.742 0-5.484-.655-2.742-3.926Z"
    />
  </svg>
)

export default TTLogo
